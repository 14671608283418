import { createSlice } from '@reduxjs/toolkit'

export const currentReviewSlice = createSlice({
  name: 'currentReview',
  initialState: "",
  reducers: {
    updateCurrentReview: (state, action) => {
      return action.payload;
    },
  },
})

export const { updateCurrentReview } = currentReviewSlice.actions

export default currentReviewSlice.reducer
