import { configureStore } from '@reduxjs/toolkit';
import shippingReducer from './reducers/shipping';
import coffeesReducer from './reducers/coffees';
import productsReducer from './reducers/products';
import currentReviewReducer from './reducers/currentReview';

export default configureStore({
  reducer: {
    shipping: shippingReducer,
    coffees: coffeesReducer,
    products: productsReducer,
    currentReview: currentReviewReducer
  },
})
