import { createSlice } from '@reduxjs/toolkit'

export const coffeesSlice = createSlice({
  name: 'coffees',
  initialState: [],
  reducers: {
    updateCoffees: (state, action) => {
      return action.payload;
    },
  },
})

export const { updateCoffees } = coffeesSlice.actions

export default coffeesSlice.reducer
