import { createSlice } from '@reduxjs/toolkit'

export const shippingSlice = createSlice({
  name: 'shipping',
  initialState: {
    zipcode: '',
    city: '',
    prices: [],
    currentPrice: '',
    currentPage: 'calculator',
    max: 10
  },
  reducers: {
    updateShipping: (state, action) => {
      let data = { ...state, ...action.payload };

      return data;
    },
  },
})

export const { updateShipping } = shippingSlice.actions

export default shippingSlice.reducer
